import { computed, reactive, Ref, ref, watchEffect } from "vue";
import { isDefined } from "@vueuse/core";
import { api } from "@/backend";
import { user } from "@/user";
import { auth } from "@/authService";
import {
  intersection,
  isNonEmptyString,
  isNonEmptyValue,
  makeId,
  removeNullOrEmptyProps,
} from "@/utils";

import { FeedStylePreset } from "@/types";
import {
  CreateFeedRequest,
  FeedCreationChannel,
  FeedCreationFormat,
  FeedDeliveryMethod,
  FeedModerationMethod,
  GetFeedConfigResponse,
  ThemeSelectListItem,
  UpdateFeedRequest,
} from "@be/api";
import { getPackage } from "./menu";
import { addNewlyCreatedFeed } from "@/state/feeds";

// Using GetFeedConfigResponse as the base model for the feed config.
type ServerFeedConfigModel = Required<GetFeedConfigResponse>;

type PreviewModel = {
  RenderId: string;
  Data: any;
  Options: Record<string, any>;
};

type FeedConfigChannel = Required<FeedCreationChannel>;
type FeedConfigFormat = Required<FeedCreationFormat>;
type SimpleListItem = { name: string; value: string };
type FontFamilyOption = { name: string; value: string; deprecated?: boolean };

type FeedConfig = {
  feedId?: number;
  productId?: number | null;
  accountId?: number;
  packageId?: number;
  formatId?: number;
  name?: string;
  deliveryMethod?: FeedDeliveryMethod;
  moderationMethod?: FeedModerationMethod;
  channels: number[];
  options: Record<string, any>;
};

type IntroBgStyle = "solid" | "panels" | "circles" | "tunnel";

type IntroFgStyle = "oversize" | "center" | "iconTitle";

type IntroCenterStyle =
  | "slide-up"
  | "slide-down"
  | "slide-left"
  | "slide-right"
  | "pop";

type IntroOptions = {
  hideIntro?: boolean;
  introBgColor?: string;
  introTextColor?: string;
  introFgStyle?: IntroFgStyle;
  introBgStyle?: IntroBgStyle;
  introCustomMessage?: string;
  introHideTitle?: boolean;
  introCenterStyle?: IntroCenterStyle;
  introRightJustifyText?: boolean;
  introTitle?: string;
};

export const serverConfigModel = ref<ServerFeedConfigModel | null>(null);

export const packageName = computed(
  () => serverConfigModel.value?.packageName ?? "Unknown Product",
);

export const feedUrl = computed(() => serverConfigModel.value?.feedUrl);

export const productSlug = computed(
  () => serverConfigModel.value?.productSlug ?? "unknown-product",
);

export const isSaving = ref(false);
export const isLoading = ref(false);
export const isLoaded = ref(false);
export const isInitialized = ref(false);

export const previewModel = ref<PreviewModel | null>(null);

/**
 * When true, the feed configuration for the current package
 * is ready to be displayed to the user.
 *  - The Feed Config info is loaded from the server
 *  - Any other tasks that a custom feed config page requires are complete.
 *  - This value will be set by the custom feed config component.
 */
export const isReady = ref(false);

const configCopy = ref<FeedConfig | null>(null);
export const config = reactive<FeedConfig>({
  feedId: undefined,
  productId: undefined,
  accountId: undefined,
  packageId: undefined,
  formatId: undefined,
  name: undefined,
  deliveryMethod: undefined,
  moderationMethod: undefined,
  channels: [],
  options: {},
});

/**
 * By default iframe previews will be enabled if the
 * server config model provides a `dataUrl` and `htmlUrl`.
 *
 * For some products we want to disable the iframe preview.
 */
export const isIframePreviewDisabled = ref(false);

export const isDataDelivery = computed(() => {
  return config.deliveryMethod === "Json" || config.deliveryMethod === "Xml";
});

/**
 * Selected Format Metadata
 */
export const selectedFormat = computed(() => {
  return serverConfigModel.value?.formats?.find(
    (f) => f.formatId === config.formatId,
  );
});

/**
 * Clean up channel names based on known patterns.
 */
function cleanChannelName(name: string): string {
  name = name.trim();
  if (name.startsWith("AP") && name.endsWith("News Graphics")) {
    return name.substring(3, name.length - "News Graphics".length);
  }

  if (name.startsWith("The Canadian Press")) {
    return name.substring("The Canadian Press".length).trim();
  }

  //Financial Markets TSX Image
  if (name.startsWith("Financial ") && name.endsWith(" Image")) {
    return name.substring(10, name.length - " Image".length);
  }

  //Sports Central
  if (name.startsWith("Sports Central -")) {
    return name.substring("Sports Central -".length).trim();
  }
  if (name.startsWith("Sports -")) {
    return name.substring("Sports -".length).trim();
  }

  if (name.startsWith("Core Weather")) {
    return name.substring("Core Weather".length).trim();
  }

  return name;
}

/**
 * Channel options based on
 * - selected delivery method
 * - selected format
 * - selected language
 */
export const channelOptions = computed((): FeedConfigChannel[] => {
  if (config.deliveryMethod) {
    const dm =
      serverConfigModel.value?.deliveryMethods?.[config.deliveryMethod];
    const channels = (serverConfigModel.value?.channels ??
      []) as FeedConfigChannel[];
    const deliveryChannels = dm?.channelIds ?? [];
    const formatChannels = selectedFormat.value?.channelIds ?? [];
    const selectedLanguage = config.options.language?.toLowerCase() ?? "";

    return channels
      ?.filter((c) => {
        const delivery =
          deliveryChannels.length === 0 ||
          deliveryChannels.includes(c.channelId);

        const format =
          formatChannels.length === 0 || formatChannels.includes(c.channelId);

        const culture = c.culture?.toLowerCase() ?? "";

        const language =
          culture.length === 0 ||
          selectedLanguage === "" ||
          selectedLanguage === "all" ||
          selectedLanguage === culture ||
          culture === "(none-specific)";
        return delivery && format && language;
      })
      .map((c) => ({
        ...c,
        name: cleanChannelName(c.name!),
      })) as FeedConfigChannel[];
  }
  return [];
});

/**
 * Format options for the selected delivery method
 */
export const formatOptions = computed(() => {
  // Order of aspectRatios
  const sortOrder = [
    "16:9",
    "4:3",
    "3:2",
    "1:1",
    "2:3",
    "9:16",
    "43:320",
    "320:43",
    null,
  ];

  const options = serverConfigModel.value?.formats as FeedConfigFormat[];

  // Return options ordered by the sortOrder array
  return options.sort((a, b) => {
    return sortOrder.indexOf(a.aspectRatio) - sortOrder.indexOf(b.aspectRatio);
  });
});

export const deliveryMethodOptions = computed(() => {
  //const order = serverConfigModel.value?.deliveryMethodsConfig.deliveryMethodOrder ?? [];
  const order: FeedDeliveryMethod[] = [
    "Html",
    "MediaRss",
    "DirectUrl",
    "Json",
    "Xml",
    "Flash",
  ];

  return order.reduce(
    (acc, dm) => {
      const dmConfig = serverConfigModel.value?.deliveryMethods?.[dm];
      let label = dm as string;
      if (label === "MediaRss") {
        label = "Media RSS";
      } else if (label === "DirectUrl") {
        label = "Direct URL";
      } else if (label === "Json") {
        label = "JSON";
      }
      if (dmConfig) {
        acc.push({ value: dm, label });
      }
      return acc;
    },
    [] as { value: FeedDeliveryMethod; label: string }[],
  );
});

export const fontFamilyOptions = computed(() => {
  if (config.deliveryMethod === undefined) {
    return [];
  }

  return (serverConfigModel.value?.deliveryMethods?.[config.deliveryMethod]
    ?.options?.fontFamily?.selectList ?? []) as FontFamilyOption[];
});

export const backgroundTypeOptions = computed(() => {
  if (config.deliveryMethod === undefined) {
    return [];
  }

  return (serverConfigModel.value?.deliveryMethods?.[config.deliveryMethod]
    ?.options?.backgroundType?.selectList ?? []) as SimpleListItem[];
});

/**
 * Theme options for the selected delivery method
 */
export const themeOptions = computed((): ThemeSelectListItem[] => {
  if (config.deliveryMethod) {
    return (
      serverConfigModel.value?.deliveryMethods?.[config.deliveryMethod]?.options
        ?.theme?.selectList ?? []
    );
  }
  return [];
});

/**
 * Language options filtered by...
 * - selected delivery method
 * - selected format
 * - available channel languages
 */
export const languageOptions = computed(() => {
  if (config.deliveryMethod) {
    const dm =
      serverConfigModel.value?.deliveryMethods?.[config.deliveryMethod];
    let list = (dm?.options?.language?.selectList ?? []).filter(
      (l, i, self) => self.findIndex((s) => s.value === l.value) === i,
    ) as SimpleListItem[];

    if (serverConfigModel.value?.productSlug === "news-graphics") {
      const deliveryChannels = dm?.channelIds ?? [];
      const formatChannels = selectedFormat.value?.channelIds ?? [];

      // Get available channels
      const channelIds = intersection(deliveryChannels, formatChannels);

      // Get unique cultures for available channels
      const uniqueChannelCultures: string[] = [];

      serverConfigModel.value?.channels?.forEach((c) => {
        const culture = c.culture?.toLowerCase() ?? "";
        if (
          culture.length > 0 &&
          channelIds.includes(c.channelId) &&
          !uniqueChannelCultures.includes(culture)
        ) {
          uniqueChannelCultures.push(culture);
        }
      });

      list = list.filter((l) =>
        uniqueChannelCultures.includes(l.value as string),
      );
    }

    list.unshift({
      name: "All Languages",
      value: "all",
    });
    return list;
  }
  return [];
});

/**
 * Watch for changes to theme options
 * If a user selects a theme that is only available for _some_
 * delivery methods and they decide to change delivery methods
 * where their theme selection is no longer valid, we want to
 * deselect the theme.
 */
watchEffect(() => {
  if (
    isDefined(config.options.theme) &&
    themeOptions.value.every((t) => t.value !== config.options.theme)
  ) {
    config.options.theme = null;
  }
});

function coerceType(value: unknown) {
  if (typeof value === "string") {
    if (value === "false") {
      return false;
    }
    if (value === "true") {
      return true;
    }

    let parsedValue: number =
      value.indexOf(".") > -1 ? parseFloat(value) : parseInt(value);
    if (!isNaN(parsedValue)) {
      return parsedValue;
    }
  }
  return value;
}

/**
 * Scans all the available options for delivery method and returns
 * a single object containing all unique keys present.
 *
 * If the config is an update, it will populate any existing option values.
 * If it is a new feed, it will populate the default value for each option.
 */
function getOptions(config: ServerFeedConfigModel) {
  const isUpdate = config.options && Object.keys(config.options).length > 0;
  let existingValues: Record<string, any> = {};
  for (let key in config.options || {}) {
    existingValues[key] = config.options?.[key] ?? "";
  }
  let distinctOptions = Object.keys(config.deliveryMethods || {}).reduce(
    (x, dm) => {
      let dmOptions =
        config.deliveryMethods?.[dm as FeedDeliveryMethod]?.options ?? {};
      Object.keys(dmOptions).forEach((key) => {
        let value: any = null;
        if (key === "location") {
          value = existingValues[key];
        } else if (isUpdate) {
          value = coerceType(existingValues[key]);
        } else if (dmOptions[key] && "defaultValue" in dmOptions[key]) {
          if (
            key === "language" &&
            typeof dmOptions[key].defaultValue === "object"
          ) {
            value = dmOptions[key].defaultValue.value;
          } else {
            value = coerceType(dmOptions[key].defaultValue);
          }
        }
        x[key] = value;
      });
      return x;
    },
    existingValues || {},
  );

  if (!("width" in distinctOptions)) {
    distinctOptions.width = null;
  }
  if (!("height" in distinctOptions)) {
    distinctOptions.height = null;
  }

  return distinctOptions;
}

async function getFeedConfigFromBackend(
  feedId: Ref<string>,
  packageId: Ref<string>,
): Promise<ServerFeedConfigModel> {
  console.log("getFeedConfigFromBackend", feedId.value, packageId.value);

  if (isDefined(feedId) && feedId.value !== "new") {
    const response = await api.getFeedConfig({
      feedId: parseInt(feedId.value, 10),
    });
    const model = response.data.data!;
    return model as ServerFeedConfigModel;
  }

  if (isDefined(packageId)) {
    const token = await auth.getAccessToken();
    const authorization = `Bearer ${token}`;
    const response = await api.getFeedCreation(authorization, {
      accountId: user.value?.accountId,
      packageId: parseInt(packageId.value, 10),
    });
    const model = response.data.data!;
    return model as ServerFeedConfigModel;
  }

  return Promise.resolve({} as ServerFeedConfigModel);
}

function clearState() {
  isIframePreviewDisabled.value = false;
  serverConfigModel.value = null;
  configCopy.value = null;

  config.accountId = undefined;
  config.feedId = undefined;
  config.productId = undefined;
  config.packageId = undefined;
  config.formatId = undefined;
  config.name = undefined;
  config.deliveryMethod = undefined;
  config.moderationMethod = undefined;
  config.channels = [];
  config.options = {};
}

function getDeliveryMethod(config: ServerFeedConfigModel) {
  if (config.deliveryMethod) {
    return config.deliveryMethod;
  }

  // If there is a default delivery method, use it
  if (isDefined(config.deliveryMethodsConfig.defaultDeliveryMethod)) {
    console.log(
      "using default dm",
      config.deliveryMethodsConfig.defaultDeliveryMethod,
    );
    return config.deliveryMethodsConfig
      .defaultDeliveryMethod as FeedDeliveryMethod;
  }

  // If there is only one delivery method, use it
  let dms = Object.keys(config.deliveryMethods ?? {});
  if (dms.length === 1) {
    return dms[0] as FeedDeliveryMethod;
  }

  // Auto-select the first available delivery method
  const allDeliveryMethods: FeedDeliveryMethod[] = [
    "Html",
    "MediaRss",
    "DirectUrl",
    "Json",
    "Xml",
    "Flash",
  ];

  for (let dm of allDeliveryMethods) {
    if (dms.includes(dm)) {
      return dm;
    }
  }
}

function getFormat(config: ServerFeedConfigModel) {
  if (isDefined(config.formatId)) {
    return config.formatId;
  }

  if (Array.isArray(config.formats) && config.formats.length > 0) {
    return config.formats[0]!.formatId;
  }
}

export async function initializeFeedConfig(
  feedId: Ref<string>,
  packageId: Ref<string>,
) {
  isLoaded.value = false;
  isLoading.value = true;

  clearState();

  const isUpdate = feedId.value !== "new";

  const response = await getFeedConfigFromBackend(feedId, packageId);
  serverConfigModel.value = response as ServerFeedConfigModel;

  config.accountId = user.value?.accountId;
  config.feedId = serverConfigModel.value?.feedId;
  config.productId = serverConfigModel.value?.productId;
  config.packageId =
    serverConfigModel.value?.packageId ?? parseInt(packageId.value, 10);

  // Populate Feed Name
  config.name =
    feedId.value === "new"
      ? `New ${packageName.value} Feed`
      : (serverConfigModel.value?.name ?? "");

  // Must select delivery method
  config.deliveryMethod = getDeliveryMethod(serverConfigModel.value);

  // Must select format
  config.formatId = getFormat(serverConfigModel.value);
  config.moderationMethod = serverConfigModel.value?.moderationMethod;
  config.channels =
    serverConfigModel.value?.channels
      ?.filter((c) => {
        // If this is a new feed config, select all channels by default
        return isUpdate === false || c.isSelected;
      })
      .map((c) => c.channelId!) ?? [];

  config.options = getOptions(response);

  if (
    !isNonEmptyString(config.options.theme) &&
    themeOptions.value.length > 0
  ) {
    config.options.theme = themeOptions.value[0]?.value;
  }

  isLoading.value = false;
  isLoaded.value = true;
}

export async function completeSetup(task?: () => Promise<unknown>) {
  if (task) {
    await task();
  }
  configCopy.value = JSON.parse(JSON.stringify(config));
  isInitialized.value = true;
}

/**
 * Determines if the current feed configuration is different
 * from the configuration when it was loaded from the server.
 */
export const hasChanges = computed(() => {
  if (isLoading.value || !isDefined(configCopy)) {
    return false;
  }

  // Checking each property manually.
  // Is there a better way to do this?

  if (
    config.formatId !== configCopy.value.formatId ||
    config.deliveryMethod !== configCopy.value.deliveryMethod ||
    config.moderationMethod !== configCopy.value.moderationMethod ||
    config.name !== configCopy.value.name
  ) {
    console.log("hasChanges: 1");
    return true;
  }

  // Are channel selections the same?
  if (
    config.channels.length !== configCopy.value.channels.length ||
    config.channels.some((c) => !configCopy.value.channels.includes(c))
  ) {
    console.log("hasChanges: 2");
    return true;
  }

  // Are options the same?
  const optionsA = removeNullOrEmptyProps(
    JSON.parse(JSON.stringify(config.options)),
  );
  const optionsB = removeNullOrEmptyProps(
    JSON.parse(JSON.stringify(configCopy.value.options)),
  );

  const aKeys = Object.keys(optionsA);
  const bKeys = Object.keys(optionsB);

  if (aKeys.length !== bKeys.length) {
    console.log("hasChanges: 3");
    return true;
  }

  const optionsResult = aKeys.some((key) => {
    const aVal = optionsA[key];
    const bVal = optionsB[key];

    // Handle special cases like undefined, NaN
    if (Number.isNaN(aVal) && Number.isNaN(bVal)) {
      return false;
    }

    return aVal !== bVal;
  });

  console.log("hasChanges: 4");

  return optionsResult;
});

export function disableIframePreview() {
  isIframePreviewDisabled.value = true;
}

/**
 * Applies all style preset options to the feed config.
 */
export function applyStylePreset(preset?: FeedStylePreset) {
  if (preset === undefined) {
    return;
  }
  for (const [key, value] of Object.entries(preset.options)) {
    if (isNonEmptyValue(value)) {
      config.options[key] = value;
    }
  }
  config.options.presetId = preset.id;
}

export function setIntroOptions(options: IntroOptions | undefined) {
  const introOptions: (keyof IntroOptions)[] = [
    "hideIntro",
    "introBgColor",
    "introTextColor",
    "introFgStyle",
    "introBgStyle",
    "introCustomMessage",
    "introHideTitle",
    "introCenterStyle",
    "introRightJustifyText",
  ];
  introOptions.forEach((key) => {
    if (options && options[key] !== undefined) {
      config.options[key] = options[key];
    } else {
      config.options[key] = null;
    }
  });
}

export async function createFeed(config: FeedConfig) {
  // Remove all null values from options
  const options = { ...config.options };
  Object.keys(options).forEach((key) => {
    if (options[key] === null) {
      delete options[key];
    }
  });

  const feed: CreateFeedRequest = {
    packageId: config.packageId!,
    formatId: config.formatId,
    name: config.name ?? "Untitled Feed",
    deliveryMethod: config.deliveryMethod,
    moderationMethod: config.moderationMethod,
    options: options,
    channels: config.channels.map((channelId) => ({
      channelId,
      isSelected: true,
    })),
  };

  const response = await api.createFeed(feed);
  const newFeed = response.data.data;
  if (newFeed === undefined) {
    throw new Error("Failed to create feed");
  }

  // Save new feedId into config state
  config.feedId = newFeed.feedId;

  // Store new Url into serverConfigModel ref
  // We do this rather than re-fetching the entire feed
  if (newFeed.url && serverConfigModel.value) {
    serverConfigModel.value.feedUrl = newFeed.url;
  }

  return newFeed;
}

export async function saveFeed() {
  isSaving.value = true;

  // If the feedId is undefined, we are creating a new feed.
  if (config.feedId === undefined) {
    return createFeed(config).then((newFeedConfig) => {
      isSaving.value = false;
      completeSetup();

      // Add feed to our in-memory feeds list.
      addNewlyCreatedFeed(
        newFeedConfig,
        config.packageId!,
        config.options.theme,
      );

      return newFeedConfig;
    });
  }

  // Save existing feed
  const feed: UpdateFeedRequest = {
    feedId: config.feedId!,
    formatId: config.formatId,
    name: config.name ?? "Untitled Feed",
    deliveryMethod: config.deliveryMethod,
    moderationMethod: config.moderationMethod,
    options: config.options,
    channels: config.channels.map((channelId) => ({
      channelId,
      isSelected: true,
    })),
  };

  const response = await api.updateFeed(feed);
  config.feedId = response.data.data?.feedId;

  isSaving.value = false;
  completeSetup();
}

export function saveConfigToLocalStorage() {
  const id = makeId();

  const pkg = getPackage(config.packageId!);

  const data = {
    packageId: config.packageId,
    packageSlug: pkg?.slug,
    config: { ...config },
  };

  localStorage.setItem(`config_${id}`, JSON.stringify(data));
  return id;
}

/**
 * When an unauthenticated user attempts to save, they are prompted
 * to either signin or create an account. This takes them away from the
 * page and will redirect them back to /handlesave/:storageId.
 *
 * Here we check for the storageId param and if it exists, we will
 * load the config from local storage and create the feed.
 */
export async function handleSaveOnAuth(storageId: string) {
  const storedValue = localStorage.getItem(`config_${storageId}`);
  if (!storedValue) {
    console.error(`Unable to load config ${storageId} from local storage`);
    return;
  }

  const storedData = JSON.parse(storedValue);

  const packageId = storedData.packageId as number;
  const packageSlug = storedData.packageSlug as string;
  const config = storedData.config as FeedConfig;

  if (config) {
    const newConfig = await createFeed(config);
    if (newConfig.feedId) {
      localStorage.removeItem(`config_${storageId}`);
      return {
        packageId: packageId,
        slug: packageSlug,
        feedId: newConfig.feedId,
      };
    }
  } else {
    console.error(`Unable to load 'config_${storageId}' from local storage`);
  }
}
