import { nextTick } from "vue";
import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";

import { get, isDefined, useTitle } from "@vueuse/core";

import { auth } from "./authService";
import { user as authedUser, updateUserProfile } from "./user";
import { populateAccount, clearAccount, populateChildAccount } from "./account";
import appSettings from "./appSettings";
import { handleSaveOnAuth } from "./state/feedConfig";

const ContentMenuView = () => import("./views/ContentMenu.vue");
const FeedsView = () => import("./views/FeedsView.vue");
const SettingsView = () => import("./views/Settings.vue");
const SubscribeView = () => import("./views/Subscribe.vue");
const ChoosePlanView = () => import("./views/ChoosePlan.vue");
const CreateAccountView = () => import("./views/CreateAccount.vue");
const CustomerListView = () => import("./views/resellers/CustomerList.vue");
const UserListView = () => import("./views/users/UserList.vue");
const UserDetailView = () => import("./views/users/UserDetail.vue");

const FeedConfig = () => import("./components/config/FeedConfig.vue");
const FeedList = () => import("./components/FeedList.vue");

const title = useTitle();
const DEFAULT_TITLE = get(title.value) ?? "";

export function buildRootPath(currentRoute: any) {
  // used to build the base path for the customer nav links.
  // Required for nested child paths such as feeds/setup/:feed_id. Without this it would cause the
  // the router-link 'to' to become feeds/setup instead of feeds which breaks the nav
  let path = "";
  if (currentRoute.matched.length > 0) {
    let root = currentRoute.matched[0].path;
    if (root.includes("/customers/:customer_id")) {
      const custId = currentRoute.params.customer_id;
      path = root.replace(":customer_id", custId);
    }
  }
  return path;
}

const commonRoutes: RouteRecordRaw[] = [
  {
    name: "createAccount",
    path: "/create-account",
    component: CreateAccountView,
    meta: { title: "Create an Account" },
  },
  {
    name: "handleSaveOnAuth",
    path: "/handlesave/:storageId",
    component: {
      render: () => null,
      async beforeRouteEnter(to, _from, next) {
        const storageId = to.params.storageId as string;
        const params = await handleSaveOnAuth(storageId);
        next({ name: "feedConfig", params });
      },
    },
  },
  {
    name: "signinSilent",
    path: "/signin-silent-oidc",
    component: {
      render: () => null,
      template: "",
      beforeRouteEnter(_to, _from, next) {
        auth.handleSilentRenew();
        next();
      },
    },
  },
  {
    name: "signin-oidc",
    path: "/signin-oidc",
    component: {
      render: () => null,
      beforeRouteEnter(_to, _from, next) {
        auth.handleCallback();
        next();
      },
    },
  },
  {
    name: "signout",
    path: "/signout",
    component: {
      render: () => null,
      beforeRouteEnter() {
        auth.logout();
      },
    },
  },
];

const customerRoutes: RouteRecordRaw[] = [
  {
    name: "contentMenu",
    path: "",
    alias: "/",
    component: ContentMenuView,
    meta: { title: "Content Menu" },
  },
  {
    name: "package",
    path: "/p/:packageId/:slug",
    component: FeedsView,
    children: [
      {
        path: "",
        name: "feedList",
        component: FeedList,
        meta: { title: "Feed List" },
      },
      {
        path: "/p/:packageId/:slug/:feedId",
        name: "feedConfig",
        component: FeedConfig,
        meta: { title: "Feed" },
      },
    ],
  },
  {
    name: "settings",
    path: "/settings",
    component: SettingsView,
    meta: { title: "Settings", requiresAuth: true },
  },
  {
    name: "subscribe",
    path: "/subscribe",
    component: SubscribeView,
    meta: { title: "Subscribe" },
  },
  {
    name: "choosePlan",
    path: "/choose-plan",
    component: ChoosePlanView,
    meta: { title: "Choose a Plan" },
  },

  {
    name: "userList",
    path: "/users",
    component: UserListView,
    meta: { requiresAuth: true },
  },
  {
    name: "userDetail",
    path: "/users/:user_id",
    component: UserDetailView,
  },
];

const resellerRoutes: RouteRecordRaw[] = [
  {
    name: "customerList",
    path: "/customers",
    component: CustomerListView,
    meta: { title: "Customers", requiresAuth: true },
  },
  {
    path: "/customers/:customerId",
    beforeEnter(to, _from, next) {
      const customerId = to.params.customerId;
      if (customerId) {
        populateChildAccount(Number(customerId)).then(() => {
          next();
        });
      } else {
        next({ name: "customerList" });
      }
    },
    children: [
      {
        name: "resellerContentMenu",
        path: "",
        component: ContentMenuView,
        meta: { title: "Content Menu" },
      },
      {
        name: "resellerPackage",
        path: "p/:packageId/:slug",
        component: FeedsView,
        children: [
          {
            path: "",
            name: "resellerFeedList",
            component: FeedList,
            meta: { title: "Feed List" },
          },
          {
            path: ":feedId",
            name: "resellerFeedConfig",
            component: FeedConfig,
            meta: { title: "Feed" },
          },
        ],
      },
      {
        name: "resellerSettings",
        path: "settings",
        component: SettingsView,
        meta: { title: "Settings", requiresAuth: true },
      },
      {
        name: "resellerSubscribe",
        path: "subscribe",
        component: SubscribeView,
        meta: { title: "Subscribe" },
        props: { default: true, sidebar: false },
      },
      {
        name: "resellerUserList",
        path: "users",
        component: UserListView,
        meta: { requiresAuth: true },
      },
      {
        name: "resellerUserDetail",
        path: "users/:user_id",
        component: UserDetailView,
      },
    ],
  },
  {
    path: "/signin",
    component: {
      render: () => null,
      beforeRouteEnter(to) {
        const returnUrl =
          (to.query.returnUrl as string) ?? window.location.href;
        auth.login(returnUrl);
      },
    },
  },
  {
    path: "/third-party-oauth/oAuthPopupRedirect.html",
    component: {
      render: () => "<div></div>",
    },
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes: [...commonRoutes, ...customerRoutes, ...resellerRoutes],
});

router.afterEach(async (to) => {
  nextTick(() => {
    title.value = isDefined(to.meta.title)
      ? `${to.meta.title} - ${DEFAULT_TITLE}`
      : DEFAULT_TITLE;
  });
});

const trySignIn = async () => {
  try {
    const isLoggedIn = await auth.isLoggedIn();
    if (!isLoggedIn) {
      clearAccount();
      return false;
    }

    const user = await auth.getUser();
    if (!user) {
      clearAccount();
      return false;
    }

    if (appSettings.environment.isProduction) {
      // TrackJS is added in index.html
      const TrackJS = (window as any).TrackJS;
      if (typeof TrackJS !== "undefined")
        TrackJS.configure({ userId: user.profile.email });

      const LogRocket = (window as any).LogRocket;
      if (typeof LogRocket !== "undefined")
        LogRocket.identify(user.profile.email);
    }

    updateUserProfile(user.profile);

    await populateAccount(authedUser.value?.accountId);

    return true;
  } catch (error) {
    console.error("Error during sign-in:", error);
    clearAccount();
    return false;
  }
};

router.beforeEach(async (to, from, next) => {
  if (to.query.impersonate) {
    auth.impersonate(to.query.impersonate);
    router.replace({ query: { impersonate: undefined } });
  }

  if (
    from.params.customerId &&
    to.name &&
    !to.name.toString().startsWith("reseller")
  ) {
    const resellerRouteName = `reseller${to.name.toString().charAt(0).toUpperCase()}${to.name.toString().slice(1)}`;
    if (router.hasRoute(resellerRouteName)) {
      return next({
        name: resellerRouteName,
        params: to.params,
        query: to.query,
        hash: to.hash,
      });
    }
  }

  if (to.query.delegate) {
    auth.delegate().finally(() => {
      router.replace({ query: { delegate: undefined } });
      next();
    });
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await trySignIn()) {
      next();
    } else if (to.fullPath.includes("impersonate")) {
      router.replace("/signout");
    } else {
      auth.login(to.fullPath);
    }
  } else {
    await trySignIn();
    next();
  }
});
