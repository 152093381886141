<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { user } from "@/user";
import Logo from "./Logo.vue";
import { Separator } from "@/components/ui/separator";
import { isDefined } from "@vueuse/core";
import { contextAccount } from "@/account";
import HeaderCustomer from "./HeaderCustomer.vue";
import HeaderReseller from "./HeaderReseller.vue";

const route = useRoute();

const isExternalAccount = computed(() => {
  return (
    isDefined(user.value?.externalAccount) &&
    isDefined(user.value?.externalAccount.id)
  );
});

const showHeader = computed(() => {
  return route.path !== "/create-account";
});

const showSeparator = computed(() => {
  return isExternalAccount.value === false;
});

const showUser = computed(() => {
  return user.value !== null;
});

const showSignIn = computed(() => {
  return user.value === null;
});

const showSignOut = computed(() => {
  return user.value !== null && isExternalAccount.value === false;
});

const isReseller = computed(() => {
  return user.value?.accountType === "reseller";
});

const hasResellerCustomerSelected = computed(() => {
  return route.params.customerId !== undefined;
});

const childAccountName = computed(() => {
  return contextAccount.value?.name;
});
</script>

<template>
  <nav class="flex flex-col" v-if="showHeader">
    <div class="flex justify-between">
      <RouterLink to="/"><Logo class="w-40 text-white" /></RouterLink>

      <div id="nav-links" class="flex items-center space-x-6">
        <HeaderReseller v-if="isReseller" />
        <HeaderCustomer v-else />

        <Separator v-if="showSeparator" orientation="vertical" class="h-4" />

        <RouterLink v-if="showSignIn" to="/signin" class="hover:underline">
          Sign In to Account
        </RouterLink>
        <div v-if="showUser">{{ user?.unique_name }}</div>

        <RouterLink to="/signout" v-if="showSignOut" class="hover:underline">
          Sign Out
        </RouterLink>
      </div>
    </div>
    <div
      v-if="isReseller && hasResellerCustomerSelected"
      class="mt-2 bg-background-hover p-2"
    >
      <div class="flex justify-between">
        <div>{{ childAccountName }}</div>
        <div class="flex space-x-6">
          <HeaderCustomer />
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped></style>
